exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-board-members-index-tsx": () => import("./../../../src/pages/board-members/index.tsx" /* webpackChunkName: "component---src-pages-board-members-index-tsx" */),
  "component---src-pages-change-password-index-tsx": () => import("./../../../src/pages/change-password/index.tsx" /* webpackChunkName: "component---src-pages-change-password-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-contentful-article-collection-slug-index-tsx": () => import("./../../../src/pages/{ContentfulArticleCollection.slug}/index.tsx" /* webpackChunkName: "component---src-pages-contentful-article-collection-slug-index-tsx" */),
  "component---src-pages-contentful-article-slug-index-tsx": () => import("./../../../src/pages/{ContentfulArticle.slug}/index.tsx" /* webpackChunkName: "component---src-pages-contentful-article-slug-index-tsx" */),
  "component---src-pages-dashboard-events-index-tsx": () => import("./../../../src/pages/dashboard/events/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-events-index-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-projects-index-tsx": () => import("./../../../src/pages/dashboard/projects/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-projects-index-tsx" */),
  "component---src-pages-dashboard-tenders-contentful-tender-slug-index-tsx": () => import("./../../../src/pages/dashboard/tenders/{ContentfulTender.slug}/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-tenders-contentful-tender-slug-index-tsx" */),
  "component---src-pages-dashboard-tenders-index-tsx": () => import("./../../../src/pages/dashboard/tenders/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-tenders-index-tsx" */),
  "component---src-pages-dashboard-updates-index-tsx": () => import("./../../../src/pages/dashboard/updates/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-updates-index-tsx" */),
  "component---src-pages-events-event-id-index-tsx": () => import("./../../../src/pages/events/{Event._id}/index.tsx" /* webpackChunkName: "component---src-pages-events-event-id-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-tenders-contentful-tender-slug-index-tsx": () => import("./../../../src/pages/tenders/{ContentfulTender.slug}/index.tsx" /* webpackChunkName: "component---src-pages-tenders-contentful-tender-slug-index-tsx" */),
  "component---src-pages-tenders-index-tsx": () => import("./../../../src/pages/tenders/index.tsx" /* webpackChunkName: "component---src-pages-tenders-index-tsx" */)
}

