module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.12.1_gatsby@5.6.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HJT520NPZV"],"gtagConfig":{}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-twitter@5.9.0_gatsby@5.6.0/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.8.0_gatsby@5.6.0_graphql@16.6.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Agricultural Innovation Australia","short_name":"AIA","start_url":"/","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bb87a0ef7a415c0a4143d08dcd51fffd"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.6.0_babel-eslint@10.1.0_esbuild@0.17.18_react-dom@18.2.0_react@18.2.0_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
